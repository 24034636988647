// DoorComp.js
import React from "react";
import { CurrentUserContext } from '../security/CurrentUserContext';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const API_LOCK_URL = process.env.REACT_APP_API_BASE_LOCK_URL;
const LOCK_CLIENT_AUTH_TOKEN = process.env.REACT_APP_LOCK_CLIENT_AUTH_TOKEN;

const DoorComp = () => {

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser
        }
    } = useContext(CurrentUserContext);

    const handleUnlock = () => {
        fetch(`${API_LOCK_URL}/unlock`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${LOCK_CLIENT_AUTH_TOKEN}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    // Handle HTTP errors
                    return response.json().then((error) => {
                        throw new Error(error.message || "Unknown error");
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                alert(data.message);
            })
            .catch((error) => {
                console.error("Error:", error);
                alert(`An error occurred: ${error.message}`);
            });
    };

    if (!currentUserState.authenticated) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                <div>
                    <p>Please log in. Or contact administrator for Login Credentials.</p>
                    <Link to="/login">Click Here to Login</Link>
                </div>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            <button style={styles.button} onClick={handleUnlock}>
                Unlock Door
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    button: {
        fontSize: "5vw",
        padding: "10px 20px",
        cursor: "pointer",
    },
};

export default DoorComp;
    